import logo from "./logo.svg";
import "./App.css";
import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
//import Employment from "./Components/Employment";
//import Education from "./Components/Education";

function App() {
  return (
    <>
      <NavBar />
      <Header />
      <Home/>
      {/* <Employment/>
      <Education/> */}
      <Footer />
    </>
  );
}

export default App;
