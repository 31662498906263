import { React, useState, Component } from "react";
import axios from "axios";
import queryString from "query-string";

export default function Footer() {
  const [MessageTitle, setMessageTitle] = useState("");
  const [Message, setMessage] = useState("");
  const [GuestName, setGueustName] = useState("");
  const [Email, setEmailAddress] = useState("");
  const [Phone, setPhone] = useState("");

  const handleForm = (e) => {
    // console.log("Message: " + message)
    // console.log("fullName: " + fullName)
    // console.log(message)
    e.preventDefault();
    const json = {
      guestName: GuestName,
      email: Email,
      messageTitle: MessageTitle,
      messageBody: Message,
      phone: Phone,
    };

    console.log(queryString.stringify(json));
    /*fetch("https://y52rsa619e.execute-api.us-east-1.amazonaws.com/v1/contact/", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(json), // body data type must match "Content-Type" header
    }).then((data) => {
      console.log(data);
    });*/
    axios
      .post(
        "https://y52rsa619e.execute-api.us-east-1.amazonaws.com/v1/contact",
        // JSON.stringify(json),
        json /*,
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "qwe",
          },
        }*/
      )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="contact-me" id="contact">
      <h3 className="text-center slideanim">Contact Me</h3>
      <p className="text-center slideanim">
        Contact me through filling up the form:
      </p>
      <div className="container">
        <footer>
          <div className="row">
            <div className="col-md-6 slideanim">
              <div className="contact-details">
                <form onSubmit={handleForm} action="#" method="post">
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="message_title"
                        name="message_title"
                        value={MessageTitle}
                        placeholder="message title"
                        type="text"
                        onChange={(e) => setMessageTitle(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        placeholder="Message"
                        value={Message}
                        onChange={(e) => setMessage(e.target.value)}
                        rows="5"
                      ></textarea>
                    </div>
                    <br />
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        value={GuestName}
                        placeholder="Name"
                        type="text"
                        onChange={(e) => setGueustName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        value={Email}
                        placeholder="Email"
                        type="email"
                        onChange={(e) => setEmailAddress(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      <input
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={Phone}
                        placeholder="Phone"
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <input className="btn btn-default btn-lg" type="submit" />

                      <span className="glyphicon glyphicon-menu-right"></span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 slideanim">
              <div className="contact-info">
                <h4>Contact Info</h4>
                <p>
                  Experienced Software developer skilled in building robust,
                  secure, and compliant software solutions for diverse
                  industries.
                </p>
                <div className="footer-info">
                  <div>
                    <i className="glyphicon glyphicon-globe"></i>
                    <p className="p1">1046 Bronx</p>
                    <p className="p2">New York, USA</p>
                  </div>
                  <div>
                    <i className="glyphicon glyphicon-phone-alt"></i>
                    <p className="p1">+1 6418192687</p>
                  </div>
                  <div>
                    <i className="glyphicon glyphicon-envelope"></i>
                    <p className="p1">
                      <a href="mailto:sumayyajahan19@gmail.com">
                        sumayyajahan19@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
                <h5>Personal Profile</h5>
                <p>
                  Ready for the next challenge in the areas of software
                  engineering as a technically proficient analyst, I come
                  equipped with solid business and technical acumen, and drive
                  to transform raw data into insightful business intelligence to
                  succeed in challenging new role with proven organizational,
                  time management, communication, and leadership strengths
                  reliably utilized to plan, coordinate, and execute multiple
                  tasks in support of profitable operations.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="copyright">
            <p className="text-center">
              © 2016 My Profile. All Rights Reserved | Designed & Developed by{" "}
              <a href="https://sumayyaportfolio.net/">Sumayya Jahan</a>
            </p>
          </div>
          <a href="#myPage" title="To Top">
            <span className="glyphicon glyphicon-chevron-up"></span>
          </a>
        </footer>
      </div>
    </div>

    //     // <div>
    //     //   <form onSubmit={handleMessage}>

    //     //     <input value={message} onChange={(e => setMessage(e.target.value))} />

    //     //     <input type="submit" />
    //     //   </form>
    //     // </div>

    // <div>
    //   <form onSubmit={handleForm}>
    //     <input value={message} onChange={(e) => setMessage(e.target.value)} />
    //     <br />
    //     <br />

    //     <input value={fullName} onChange={(e) => setFullName(e.target.value)} />
    //     <br />
    //     <br />

    //     <input
    //       value={emailAddress}
    //       onChange={(e) => setEmailAddress(e.target.value)}
    //     />
    //     <br />
    //     <br />

    //     <input type="submit" />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //     <br />
    //   </form>
    // </div>
  );
}

// export default function Footer () {
//   const [message, setMessage] = useState();
//   const [GueustName, setGueustName] = useState();
//   const [emailAddress, setEmailAddress] = useState();

//   const handleMessage = (e) => {
//     console.log(e)
//     axios
//       .post(
//         "https://uvrkyotbvauumxsn2a2jj3igxy0dywdq.lambda-url.us-east-1.on.aws/",
//         { message,fullName, emailAddress }
//       )
//       .then((response) => console.log(response))
//       .catch((response) => console.log(response));
//   };

//   return (
//     <div className="contact-me" id="contact">
//       <h3 className="text-center slideanim">Contact Me</h3>
//       <p className="text-center slideanim">
//         Contact me through filling up the form:
//       </p>
//       <div className="container">
//         <footer>
//           <div className="row">

//             <div className="col-md-6 slideanim">
//               <div className="contact-details">
//                 <form onSubmit={handleMessage} action="#" method="post">
//                   <div className="row">
//                     <div className="col-sm-12 form-group">
//                       <input
//                         className="form-control"
//                         id="message_title"
//                         name="message_title" value={MessageTitle}
//                         placeholder="message title"
//                         type="text"
//                         onChange={(e => setMessageTitle(e.target.value))}
//                         required
//                       />
//                     </div>
//                   <textarea
//                     className="form-control"
//                     id="message"
//                     name="message"
//                     placeholder="Message"
//                     value={Message}
//                     onChange={e => setComment(e.target.value)}
//                     rows="5"
//                   ></textarea>
//                   <br />
//                     <div className="col-sm-12 form-group">
//                       <input
//                         className="form-control"
//                         id="name"
//                         name="name" value={GueustName}
//                         placeholder="Name"
//                         type="text"
//                         onChange={(e => setGueustName(e.target.value))}
//                         required
//                       />
//                     </div>
//                     <div className="col-sm-12 form-group">
//                       <input
//                         className="form-control"
//                         id="email"
//                         name="email"
//                         value={Email}
//                         placeholder="Email"
//                         type="email"
//                         onChange={(e => setEmailAddress(e.target.value))}
//                         required
//                       />
//                     </div>
//                     <div className="col-sm-12 form-group">
//                       <input
//                         className="form-control"
//                         id="phone"
//                         name="phone"
//                         value={phone}
//                         placeholder="Phone"
//                         type="email"
//                         onChange={(e => setPhone(e.target.value))}
//                         required
//                       />
//                     </div>
//                   </div>

//                   <div className="row">
//                     <div className="col-sm-12 form-group">
//                       <input className="btn btn-default btn-lg" type="submit" />
//                         Send
//                         <span className="glyphicon glyphicon-menu-right"></span>

//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div className="col-md-6 slideanim">
//               <div className="contact-info">
//                 <h4>Contact Info</h4>
//                 <p>
//                   Lorem Ipsum is simply dummy text of the printing and
//                   typesetting industry.
//                 </p>
//                 <div className="footer-info">
//                   <div>
//                     <i className="glyphicon glyphicon-globe"></i>
//                     <p className="p1">77 Jack Street</p>
//                     <p className="p2">Chicago, USA</p>
//                   </div>
//                   <div>
//                     <i className="glyphicon glyphicon-phone-alt"></i>
//                     <p className="p1">+1 515 151515</p>
//                     <p className="p2">+00 1010101010</p>
//                   </div>
//                   <div>
//                     <i className="glyphicon glyphicon-envelope"></i>
//                     <p className="p1">
//                       <a href="mailto:myemail@something.com">
//                         myemail1@example.com
//                       </a>
//                     </p>
//                     <p className="p2">
//                       <a href="mailto:myemail@nothing.com">
//                         myemail2@example.com
//                       </a>
//                     </p>
//                   </div>
//                 </div>
//                 <h5>Personal Profile</h5>
//                 <p>
//                   Lorem Ipsum is simply dummy text of the printing and
//                   typesetting industry.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <hr />
//           <div className="copyright">
//             <p className="text-center">
//               © 2016 My Profile. All Rights Reserved | Design by{" "}
//               <a href="http://w3layouts.com">W3layouts</a>
//             </p>
//           </div>
//           <a href="#myPage" title="To Top">
//             <span className="glyphicon glyphicon-chevron-up"></span>
//           </a>
//         </footer>
//       </div>
//     </div>

//     // <div>
//     //   <form onSubmit={handleMessage}>

//     //     <input value={message} onChange={(e => setMessage(e.target.value))} />

//     //     <input type="submit" />
//     //   </form>
//     // </div>
//   );
// }

// // function Footer extends Component {
// //   constructor(props) {
// //     super(props);

// //     this.state = {
// //       name : null,
// //       email : null,
// //       comments : null
// //     };
// //   }

// //   nameChangeHandler = (event) => {
// //       console.log(event.target.value);
// //   }

// //   submitHandler = (event) => {
// //     event.preventDefault();

// //     console.log(this.state);
// //     const name = event.target.name.value;
// //     const email = event.target.email.value;
// //     const comments = event.target.comments.value;
// //     this.setState({name, email, comments}, () => {
// //       console.log(this.state);
// //       event.target.name.value = "";
// //       event.target.email.value = "";
// //       event.target.comments.value = "";
// //     });
// //   }

// //   render() {
// //     return (
// //       <div className="contact-me" id="contact">
// //       <h3 className="text-center slideanim">Contact Me</h3>
// //       <p className="text-center slideanim">
// //         Contact me through filling up the form:
// //       </p>
// //       <div className="container">
// //         <footer>
// //           <div className="row">
// //             <div className="col-md-6 slideanim">
// //               <div className="contact-details">
// //                 <form onSubmit={this.submitHandler} action="#" method="post">
// //                   <div className="row">
// //                     <div className="col-sm-12 form-group">
// //                       <input className="form-control" id="name" name="name" placeholder="Name" type="text" onKeyUp={this.nameChangeHandler} required/>
// //                     </div>
// //                     <div className="col-sm-12 form-group">
// //                       <input className="form-control" id="email" name="email" placeholder="Email" type="email" required/>
// //                     </div>
// //                   </div>
// //                   <textarea className="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea>
// //                   <br />
// //                   <div className="row">
// //                     <div className="col-sm-12 form-group">
// //                       <button className="btn btn-default btn-lg" type="submit">
// //                        Send
// //                         <span className="glyphicon glyphicon-menu-right"></span>
// //                       </button>
// //                     </div>
// //                   </div>
// //                 </form>
// //               </div>
// //             </div>
// //             <div className="col-md-6 slideanim">
// //               <div className="contact-info">
// //                 <h4>Contact Info</h4>
// //                 <p>
// //                   Lorem Ipsum is simply dummy text of the printing and
// //                   typesetting industry.
// //                 </p>
// //                 <div className="footer-info">
// //                   <div>
// //                     <i className="glyphicon glyphicon-globe"></i>
// //                     <p className="p1">77 Jack Street</p>
// //                     <p className="p2">Chicago, USA</p>
// //                   </div>
// //                   <div>
// //                     <i className="glyphicon glyphicon-phone-alt"></i>
// //                     <p className="p1">+1 515 151515</p>
// //                     <p className="p2">+00 1010101010</p>
// //                   </div>
// //                   <div>
// //                     <i className="glyphicon glyphicon-envelope"></i>
// //                     <p className="p1">
// //                       <a href="mailto:myemail@something.com">
// //                         myemail1@example.com
// //                       </a>
// //                     </p>
// //                     <p className="p2">
// //                       <a href="mailto:myemail@nothing.com">
// //                         myemail2@example.com
// //                       </a>
// //                     </p>
// //                   </div>
// //                 </div>
// //                 <h5>Personal Profile</h5>
// //                 <p>
// //                   Lorem Ipsum is simply dummy text of the printing and
// //                   typesetting industry.
// //                 </p>
// //               </div>
// //             </div>
// //           </div>
// //           <hr />
// //           <div className="copyright">
// //             <p className="text-center">
// //               © 2016 My Profile. All Rights Reserved | Design by{" "}
// //               <a href="http://w3layouts.com">W3layouts</a>
// //             </p>
// //           </div>
// //           <a href="#myPage" title="To Top">
// //             <span className="glyphicon glyphicon-chevron-up"></span>
// //           </a>
// //         </footer>
// //       </div>
// //     </div>
// //     );
// //   }
// // }

// // export default Footer;
