import React from "react";
//import manImage from "./images/man.jpg";

function Home(props) {
  return (
    <div>
      <div className="about-me" id="about">
        <h3 className="text-center slideanim">About My Skills</h3>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-xs-12">
              <div className="mydetails slideanim text-center">
                <img
                  src="images/man.jpg"
                  className="img-circle img-responsive"
                  alt="Generic placeholder image"
                  width="200"
                  height="200"
                />
                <h3>Sumayya Jahan</h3>
                <p>Java Developer | PHP Developer</p>
                <hr />
                
                <ul>
                  Software Development Life Cycle
                  <li> Frontend , Backend Design </li>
                  <li>Testing , Database Design</li>
                  <li>Full Stack Development ,Agile ,Scrum Methodologies</li>
                </ul>
                <div className="social-icons">
                  <a href="#">
                    <span className="linkedin"></span>
                  </a>
                  <a href="#">
                    <p>
                      <a
                        href="https://docs.google.com/document/d/1gEJ30b9nJjIzEoduPFpoJBYUilU6yvuo/edit?usp=sharing&ouid=108129096130470468759&rtpof=true&sd=true"
                        class="btn btn-success" target="_blank">
                        Downloadble Resume
                      </a>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-xs-12">
              <div className="myskills slideanim">
                <h3 className="text-center">About Me</h3>
                <p className="skill-text">
                  Enthusiastic Software Engineer with over 8 years of
                  professional experience contributing to all software
                  development life cycle phases. Proven experience in designing
                  and implementing functional frontend and backend systems that
                  streamline functionality and performance of applications.
                  Skilled in using technologies such as Laravel, PHP, Java,
                  Spring Core, Spring Boot, MySQL, Microservices, Agile, and
                  Azure. Well-trained in developing JAVA projects using the
                  latest technologies like Java, Spring, Spring Boot, and
                  Hibernate.
                </p>
                <div className="skill-info">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>
                            <h4>Java</h4>
                          </td>
                          <td>
                            <span className="longline1"></span>
                            <span className="shortline1"></span>
                          </td>
                          <td>
                            <p>80%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>PHP</h4>
                          </td>
                          <td>
                            <span className="longline2"></span>
                            <span className="shortline2"></span>
                          </td>
                          <td>
                            <p>90%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>Spring Boot</h4>
                          </td>
                          <td>
                            <span className="longline3"></span>
                            <span className="shortline3"></span>
                          </td>
                          <td>
                            <p>80%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>MySQL</h4>
                          </td>
                          <td>
                            <span className="longline4"></span>
                            <span className="shortline4"></span>
                          </td>
                          <td>
                            <p>90%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h4>HTML5</h4>
                          </td>
                          <td>
                            <span className="longline5"></span>
                            <span className="shortline5"></span>
                          </td>
                          <td>
                            <p>96%</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div><h4><a href="https://drive.google.com/file/d/1QtZGD7V9qYm9A5Ti5w4ubtKgVa-W1hFQ/view?usp=sharing" target="_blank">Here is my Blog about CI CD</a></h4></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="myportfolio" id="portfolio">
        <h3 class="text-center slideanim">My Portfolio</h3>
        <p class="text-center slideanim">
          My Recent Projects , Just "Click" on them to know More.
        </p>
        <section class="vertical" id="grid3d">
          <div class="grid-wrap">
            <div class="grid">
              <figure>
                <img class="slideanim" src="images/1.jpg" alt="img01" />
              </figure>
              <figure>
                <img class="slideanim" src="images/2.jpg" alt="img05" />
              </figure>
              <figure>
                <img class="slideanim" src="images/3.jpg" alt="img08" />
              </figure>
              <figure>
                <img class="slideanim" src="images/4.jpg" alt="img02" />
              </figure>
              <figure>
                <img class="slideanim" src="images/5.jpg" alt="img04" />
              </figure>
              <figure>
                <img class="slideanim" src="images/6.jpg" alt="img03" />
              </figure>
              <figure>
                <img class="slideanim" src="images/7.jpg" alt="img09" />
              </figure>
              <figure>
                <img class="slideanim" src="images/8.jpg" alt="img06" />
              </figure>
              <figure>
                <img class="slideanim" src="images/9.jpg" alt="img07" />
              </figure>
            </div>
          </div>
          <div class="content">
            <div>
              <div class="content-img">
                <img
                  src="images/1-1.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Classy Coming Soon</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/2-2.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">404 Error Page</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/3-3.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Credit Card Validation</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/4-4.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Eshop Product Detail Widget</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/5-5.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Model Profile Widget</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/6-6.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Flat Under Construction</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/7-7.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Impressive Under Construction</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/8-8.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Profile Widget</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <div>
              <div class="content-img">
                <img
                  src="images/9-9.jpg"
                  class="img-responsive"
                  alt="my projects"
                />
              </div>
              <h3 class="content-text">Travel Reservation Widget</h3>
              <p class="content-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
            <span class="loading"></span>
            <span class="icon close-content"></span>
          </div>
        </section>
      </div>
      
    </div>
  );
}

export default Home;
